form {
	.asteriskField {
		display: none;
	}
}

.form-group {
	margin-bottom: $spacer;
}

.form-inline {
	.form-group {
		margin-right: ($spacer * .5);

		label {
			margin-right: ($spacer * .5);
		}
	}

	.form-group:last-of-type {
		margin-right: 0;
	}
}

// hack to not show extra useless padding with invisible capthca
#div_id_captcha {
	margin-bottom: 0;
}

// hack to make sure captcha errors show up on forms
#div_id_captcha .invalid-feedback {
	display: inline;
}

.selectdatewidget.form-control {
	margin-bottom: ($spacer * .5);
}
