html {
	height: 100% !important;
}

body {
	padding-top: 4rem;
	display: flex;
	flex-direction: column;
	height: 100% !important;
}

main {
	flex-shrink: 0 !important;
}

.row.narrow-gutters {
	margin-right: -8px;
	margin-left: -8px;

	> .col,
	> [class*="col-"] {
		padding-right: 8px;
		padding-left: 8px;
	}
}
