.sidebar {
	#recent-posts {
		h4 {
			small {
				a {
					color: #6c757d;
				}

				a:hover {
					color: #ee802f;
				}
			}
		}

		header {
			display: flex;
			justify-content: space-between;
			width: 100%;

			h5 {
				display: inline-block;
				@include text-truncate;
			}

			small {
				white-space: nowrap;
				margin-left: ($spacer * .5);
			}
		}

		p {
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}
	}
}
