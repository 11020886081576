.index-card {
	.card-title {
		display: inline-block;
		max-width: 100%;
		@include text-truncate;
	}

	.card-subtitle {
		margin-top: -$card-spacer-y / 1.5;
		margin-bottom: $card-spacer-y;
	}

	.card-text {
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}
}


#event-cards {
	.card-text {
		-webkit-line-clamp: 3;
	}
}
