.alert ul {
	list-style-type: none;
	padding-left: 0;
	margin: 0;
}

p a {
	overflow-wrap: break-word
}


.nav-lines {
	border-bottom: 2px solid #f5f5f5;

	.nav-item {
		border-bottom: 2px solid #f5f5f5;
		margin-bottom: -2px;
		color: $secondary;
	}

	.nav-item:hover {
		border-bottom-color: #dbdbdb !important;
	}

	.nav-item.active {
		border-bottom-color: $primary !important;
		color: $primary;
	}
}
