.navbar-brand {
	.icon {
		vertical-align: top;
		width: 29px;
		height: 29px;
		margin-right: 0.25em;

		svg {
			width: 29px;
			height: 29px;
			position: static;
			top: 0;
		}
	}
}
