.icon {
	display: inline-flex;
	align-self: center;

	svg {
		height: 1em;
		width: 1em;
		stroke-width: 0;
		stroke: currentColor;
		fill: currentColor;
	}
}

.icon.svg-baseline svg {
	top: .125em;
	position: relative;
}

.icon-search {
	width: 0.9285714285714285em;
}

.icon-refresh {
	width: 0.8571428571428571em;
}

.icon-twitter {
	width: 0.9285714285714285em;
}

.icon-facebook {
	width: 0.5881428571428571em;
}

.icon-rss-square {
	width: 0.8571428571428571em;
}

.icon-github {
	width: 0.8571428571428571em;
}

.icon-angle-left {
	width: 0.375em;
}

.icon-angle-right {
	width: 0.33928571428571425em;
}

.icon-google {
	width: 0.8398571428571427em;
}

.icon-paw {
	width: 0.9285714285714285em;
}
