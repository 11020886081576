// things taken from bootstrap as-is
// needed for the rest of this file to function
$spacer: 1rem;
$white: #fff;

$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1140px,
	xxl: 1240px
);

$spacers: (
	0: 0,
	1: ($spacer * .25),
	2: ($spacer * .5),
	3: $spacer,
	4: ($spacer * 1.5),
	5: ($spacer * 2),
	6: ($spacer * 3)
);
// fonts
$font-family-sans-serif: -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

// change the primary color
$primary: #006b99; // #0088c2; preferred, workaround

// add a neat bit of blueness to the dark
$dark: #2c3840;

// link underlines only when hovering
$link-decoration: none;
$link-hover-decoration: underline;

$navbar-padding-y: $spacer * .75 !default;
$navbar-dark-color: rgba($white, .65) !default;
$navbar-dark-hover-color: rgba($white, .85) !default;
